import React from 'react';
import theme from '@style';
import i18n from '@i18n';
import {View, Text, StyleSheet} from 'react-native';
// import {ProxyTitle} from '../basic-components';
import NoData from '@/components/basic/error-pages/no-data';
import {ImageUrlType} from '@/components/basic/image';
import {TodayEarningsChartItemRes} from '../types';
// import {useInnerStyle} from '../proxy.hooks';
import TableHeader from '@/common-pages/proxy-new/basic-components/table-header';
import TableItem from '@/common-pages/proxy-new/basic-components/table-item';

export interface EarnMeItem {
  rank?: string;
  exceed?: string;
  commissionAmount?: string;
  headImg?: ImageUrlType;
}

interface EarningChartProps {
  user: TodayEarningsChartItemRes[];
  me?: EarnMeItem;
  bet?: number;
  invite?: number;
  recharge?: number;
}
const EarningsChart: React.FC<EarningChartProps> = props => {
  const {user} = props;
  // const {homeEarningStyle} = useInnerStyle();

  const headers = ['User', 'Commission', 'Type', 'Level'];
  return (
    <View
      style={[
        theme.margin.l,
        theme.borderRadius.m,
        // eslint-disable-next-line react-native/no-inline-styles
        {
          backgroundColor: '#3B1E79',
          paddingBottom: 15,
        },
      ]}>
      <View
        style={[
          theme.margin.btml,
          // eslint-disable-next-line react-native/no-inline-styles
          {
            backgroundColor: '#824AF8',
            height: 44,
            paddingLeft: 15,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          },
        ]}>
        <Text
          // eslint-disable-next-line react-native/no-inline-styles
          style={{
            fontSize: 15,
            color: '#fff',
            lineHeight: 44,
            fontWeight: 'bold',
          }}>
          {i18n.t('newProxy.home.commission-ranking')}
        </Text>
      </View>
      {/* <View style={[theme.flex.flex, theme.flex.row]}>
        <View style={[styles.viewStyle]}>
          <Text style={[styles.textStyle]}>{recharge}RS</Text>
          <Text style={[styles.labelStyle]}>Recharge</Text>
        </View>
        <View style={[styles.viewStyle]}>
          <Text style={[styles.textStyle]}>{invite}RS</Text>
          <Text style={[styles.labelStyle]}>Invite</Text>
        </View>
        <View style={[styles.viewStyle]}>
          <Text style={[styles.textStyle]}>{bet}RS</Text>
          <Text style={[styles.labelStyle]}>Bet</Text>
        </View>
      </View> */}
      <View
        style={[
          styles.herderStyle,
          theme.flex.flex,
          theme.flex.row,
          theme.flex.centerByCol,
          styles.viewMg,
        ]}>
        <TableHeader header={[...headers]} />
      </View>
      <View style={[styles.viewMg]}>
        {user.map((item, index) => {
          return (
            <View
              key={index}
              style={[index % 2 === 0 ? styles.th1 : styles.th2]}>
              <TableItem key={index} {...(item as any)} />
            </View>
          );
        })}
      </View>
      {user.length === 0 && (
        <View style={styles.noData}>
          <NoData />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  noData: {
    paddingBottom: 80,
  },
  textStyle: {
    color: '#fff',
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 'bold',
  },
  labelStyle: {
    color: 'rgba(255,255,255,.3)',
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
  },
  viewStyle: {
    flex: 1,
    marginBottom: 10,
  },
  herderStyle: {
    backgroundColor: '#4A2599',
    height: 30,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    lineHeight: 30,
  },
  th1: {
    backgroundColor: '#150D45',
  },
  th2: {
    backgroundColor: '#2B1161',
  },
  viewMg: {
    marginLeft: 12,
    marginRight: 12,
  },
});

export default EarningsChart;
